import type { QAState, SurfaceClassification } from '@scans/types';
import { qaStates, surfaceClassificationCategories } from '@scans/types';
import { viewer3dSelectors } from '@viewer3D/redux';
import type { ScanStatsView } from '@viewer3D/redux/interface';
import type { BoundingBoxWithHeight } from '@viewer3D/types';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import type { XOR } from 'ts-xor';
import { useEffectOnce } from '@core/logic';
import { useScansInScope } from './menu/ScanOrganization';

export const useScanStats = (): ScanStatsView => {
  const visibility = useSelector(viewer3dSelectors.visibleScans);
  const scans = useScansInScope().filter((scan) => scan.selectedProcessingResultId);
  const visible =
    visibility === 'ALL' ? scans : visibility === 'NONE' ? [] : scans.filter((x) => x.visible);
  const visibleLoaded = visible.filter((x) => x.loaded).length;
  const loading = visible.filter((x) => !x.loaded).length;
  let loadedVisiblePercent = 0;
  if (visibleLoaded > 0) {
    loadedVisiblePercent = (visibleLoaded / visible.length) * 100;
  }
  return {
    scope: scans.length,
    hidden: scans.length - visible.length,
    loading,
    loadedVisiblePercent,
  };
};

// A Scan/Photo has two different dates: the date where it was recorded at and the date where it was uploaded
export type DateFilterType = 'recordedAt' | 'uploadedAt';

export type DateRange = { from: string; to: string; type: DateFilterType };

export type ScanFilters = {
  qaState?: QAState;
  // setters need to be exposed always in order to switch from viewport to date range or back
  setQaState: (qaState: QAState) => void;
  removeQaState: () => void;
  surfaceClassifications?: SurfaceClassification[];
  setSurfaceClassification: (surfaceClassifications: SurfaceClassification[]) => void;
  removeSurfaceClassification: () => void;
  setDateRange: (dateRange: DateRange) => void;
  setViewport: (viewport: BoundingBoxWithHeight) => void;
} & XOR<
  {
    dateRange: DateRange;
  },
  {
    viewport: BoundingBoxWithHeight;
  }
>;

type BaseParams = {
  qaState?: QAState;
  surfaceClassifications?: string;
};

type DateRangeParams =
  | {
      beginDateGte: string;
      beginDateLte: string;
    }
  | { uploadedAtGte: string; uploadedAtLte: string };

type ViewportParams = {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
};

type FiltersRequestParams =
  | BaseParams
  | (BaseParams & DateRangeParams)
  | (BaseParams & ViewportParams);

export const encodeScanFilters = ({
  qaState,
  viewport,
  dateRange,
  surfaceClassifications,
}: ScanFilters): FiltersRequestParams => {
  const out: FiltersRequestParams = {
    qaState,
    surfaceClassifications: surfaceClassifications?.join(','),
  };

  if (viewport) {
    const { minX, maxX, minY, maxY } = viewport;
    return {
      ...out,
      minX,
      maxX,
      minY,
      maxY,
    };
  }

  if (dateRange) {
    const fromDate = DateTime.fromISO(dateRange.from, { zone: 'UTC+0' }).toISO()!;
    const toDate = DateTime.fromISO(dateRange.to, { zone: 'UTC+0' }).plus({ days: 1 }).toISO()!;
    return dateRange.type === 'recordedAt'
      ? {
          ...out,
          beginDateGte: fromDate,
          beginDateLte: toDate,
        }
      : {
          ...out,
          uploadedAtGte: fromDate,
          uploadedAtLte: toDate,
        };
  }

  return out;
};

const parseViewport = (viewportJson: string | null): BoundingBoxWithHeight | undefined => {
  if (!viewportJson) {
    return undefined;
  }
  try {
    const parsedViewport = JSON.parse(viewportJson) as BoundingBoxWithHeight;
    const containsBoundsAsNumbers = ['minX', 'maxX', 'minY', 'maxY', 'z'].every(
      (key) => typeof parsedViewport[key as keyof BoundingBoxWithHeight] === 'number',
    );
    return containsBoundsAsNumbers ? parsedViewport : undefined;
  } catch {
    return undefined;
  }
};

const parseDate = (date: string | null): string | undefined =>
  date && DateTime.fromISO(date).isValid ? date : undefined;

const parseDateRangeType = (dateRangeType: string | null): DateFilterType | undefined =>
  dateRangeType === 'recordedAt' || dateRangeType === 'uploadedAt' ? dateRangeType : undefined;

const parseDateRange = (
  from: string | null,
  to: string | null,
  type: string | null,
): DateRange | undefined =>
  parseDate(from) && parseDate(to) && parseDateRangeType(type)
    ? {
        from: from!,
        to: to!,
        type: type as DateFilterType,
      }
    : undefined;

const parseQAState = (qaState: string | null): QAState | undefined =>
  qaState && qaStates.includes(qaState as QAState) ? (qaState as QAState) : undefined;

const parseSurfaceClassification = (
  surfaceClassification: string | null,
): SurfaceClassification[] | undefined => {
  if (!surfaceClassification) {
    return undefined;
  }
  const surfaceClassificationsList = surfaceClassification.split(',') as SurfaceClassification[];
  const allCategoriesValid = surfaceClassificationsList.every((category) =>
    surfaceClassificationCategories.includes(category),
  );

  return allCategoriesValid ? surfaceClassificationsList : undefined;
};

const today = DateTime.now();
// if OPs begin their work on monday, they need to see what happened on the weekend at the building site. Otherwise, they only need to see what happened yesterday.
export const getYesterdayOrWeekend = (today: DateTime) =>
  today.minus({ days: today.weekdayLong === 'Monday' ? 3 : 1 });

export const defaultDateRange: DateRange & { type: DateFilterType } = {
  from: getYesterdayOrWeekend(today).toISODate()!,
  to: today.toISODate(),
  type: 'recordedAt',
};

/**
 * This is the central handler for the scan filters state which is used in many components.
 * It gets/sets the scan filters as query-parameters and returns them as a javascript object.
 */
export const useScanFilters = (): ScanFilters => {
  const [searchParams, setSearchParams] = useSearchParams();

  const baseFilters: Omit<ScanFilters, 'viewport' | 'dateRange' | 'dateRangeType'> = {
    qaState: parseQAState(searchParams.get('qaState')),

    setQaState: (qaState) => {
      setSearchParams(
        (prev: URLSearchParams) => {
          prev.delete('qaState');
          return [...prev.entries(), ['qaState', qaState]];
        },
        { replace: false },
      );
    },

    removeQaState: () => {
      setSearchParams(
        (prev: URLSearchParams) => {
          prev.delete('qaState');
          return prev;
        },
        { replace: false },
      );
    },

    surfaceClassifications: parseSurfaceClassification(searchParams.get('surfaceClassifications')),

    setSurfaceClassification: (surfaceClassifications) => {
      setSearchParams(
        (prev: URLSearchParams) => {
          prev.delete('surfaceClassifications');
          return [...prev.entries(), ['surfaceClassifications', surfaceClassifications.join(',')]];
        },
        { replace: false },
      );
    },

    removeSurfaceClassification: () => {
      setSearchParams(
        (prev: URLSearchParams) => {
          prev.delete('surfaceClassifications');
          return prev;
        },
        { replace: false },
      );
    },

    setViewport: (viewport) => {
      const stringifiedViewport = JSON.stringify(viewport);
      if (searchParams.get('viewport') === stringifiedViewport) return;
      setSearchParams(
        (prev: URLSearchParams) => {
          prev.delete('fromDate');
          prev.delete('toDate');
          prev.delete('dateRangeType');
          prev.delete('viewport');
          return [...prev.entries(), ['viewport', stringifiedViewport]];
        },
        { replace: false },
      );
    },

    setDateRange: ({ from, to, type }) => {
      setSearchParams(
        (prev: URLSearchParams) => {
          prev.delete('viewport');
          prev.delete('fromDate');
          prev.delete('toDate');
          prev.delete('dateRangeType');
          return [...prev.entries(), ['fromDate', from], ['toDate', to], ['dateRangeType', type]];
        },
        { replace: false },
      );
    },
  };

  useEffectOnce(() => {
    if (searchParams.get('surfaceClassifications')) return;

    // Show only trenches on initial load
    baseFilters.setSurfaceClassification(['TRENCH', 'NOT_CLASSIFIED']);
  });

  useEffect(() => {
    const parsedViewport = parseViewport(searchParams.get('viewport'));
    const parsedFromDate = parseDate(searchParams.get('fromDate'));
    const parsedToDate = parseDate(searchParams.get('toDate'));
    const parsedDateRangeType = parseDateRangeType(searchParams.get('dateRangeType'));
    if (parsedViewport || (parsedFromDate && parsedToDate && parsedDateRangeType)) {
      // if a valid viewport is set or a full valid date range including type, we don't need to do anything
      return;
    }

    // initially check query params for date range/date range type for validity and set default values if necessary
    baseFilters.setDateRange({
      from: parsedFromDate ?? defaultDateRange.from,
      to: parsedToDate ?? defaultDateRange.to,
      type: parsedDateRangeType ?? defaultDateRange.type,
    });
  }, [
    searchParams.get('viewport'),
    searchParams.get('fromDate'),
    searchParams.get('toDate'),
    searchParams.get('dateRangeType'),
  ]);

  const out = searchParams.has('viewport')
    ? {
        ...baseFilters,
        viewport: parseViewport(searchParams.get('viewport')),
      }
    : {
        ...baseFilters,
        dateRange: parseDateRange(
          searchParams.get('fromDate'),
          searchParams.get('toDate'),
          searchParams.get('dateRangeType'),
        ),
      };

  return useMemo(() => out as ScanFilters, [searchParams]); // useMemo to prevent unnecessary rerenders
};
