import { pointElementSelectors } from '@elements/redux/point-element.slice';
import { Divider } from '@mui/material';
import { viewer3dSelectors } from '@viewer3D/redux';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ElementSelect } from './ElementSelect';
import { MarkersAndConnectorsSection } from './MarkersAndConnectorsSection';
import { PipeFittings } from './PipeFittings';
import { PointElementSMADetails } from './PointElementSMADetails';
import { TrenchSelect } from './TrenchSelect';

export const TrenchPointDetails = () => {
  return <TrenchSelect />;
};

interface ElementPointDetailsProps {
  projectId: string | undefined;
}

export const ElementPointDetails = ({ projectId }: ElementPointDetailsProps) => {
  const selectedPointId = useSelector(viewer3dSelectors.selectedPointId);
  const pointElements = useSelector(pointElementSelectors.selectAll);

  const pointElement = useMemo(() => {
    return pointElements.find((pe) => pe.pointId === selectedPointId);
  }, [selectedPointId, pointElements]);

  return (
    <>
      <MarkersAndConnectorsSection selectedPointId={selectedPointId} />
      <Divider />
      <ElementSelect />
      <Divider />
      <PipeFittings />
      <Divider />
      {pointElement?.id ? (
        <PointElementSMADetails
          projectId={projectId ?? ''}
          pointElementId={pointElement.id ?? ''}
          smaNumber={pointElement.smaNumber}
        />
      ) : null}
    </>
  );
};
