import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { Plus } from '@deepup/icons';
import { OrderNumberSubType } from '@projects/types/orderNumbers';

interface PointElementAddSMADialogProps {
  open: boolean;
  onClose: () => void;
  onAddSMA: (smaValue: string) => void;
  entityType: 'pointElements';
  entityId: string;
}

export const PointElementAddSMADialog: React.FC<PointElementAddSMADialogProps> = ({
  open,
  onClose,
  onAddSMA,
  entityType,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedSubType, setSelectedSubType] = useState<OrderNumberSubType>(
    OrderNumberSubType.APL,
  );
  const [error, setError] = useState<string | null>(null);

  const handleAdd = () => {
    onAddSMA(inputValue);
    setInputValue('');
    setSelectedSubType(OrderNumberSubType.APL);
    setError(null);
    onClose();
  };

  const handleCancel = () => {
    setInputValue('');
    setSelectedSubType(OrderNumberSubType.APL);
    setError(null);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      PaperProps={{
        elevation: 11,
        style: {
          width: '360px',
        },
      }}
    >
      <DialogTitle>Add SMA</DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={1}>
          <TextField
            label="SMA Number"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter SMA Number"
            fullWidth
            autoFocus
            error={!!error}
            helperText={error}
          />

          {entityType !== 'pointElements' && (
            <FormControl fullWidth>
              <InputLabel id="subtype-label">SubType</InputLabel>
              <Select
                labelId="subtype-label"
                value={selectedSubType}
                label="SubType"
                onChange={(e) => setSelectedSubType(e.target.value as OrderNumberSubType)}
              >
                {Object.values(OrderNumberSubType).map((subType) => (
                  <MenuItem key={subType} value={subType}>
                    {subType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Button
            variant="contained"
            onClick={handleAdd}
            startIcon={<Plus />}
            disabled={inputValue.trim() === ''}
          >
            Add SMA
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={handleCancel} color="secondary" variant="outlined">
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
};
