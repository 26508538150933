import type { ThreeEvent } from '@react-three/fiber';
import { enableLayerOne, getRenderOrder } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import { ForwardedRef, forwardRef, memo, useEffect, useRef, useState } from 'react';
import type {
  BufferGeometry,
  ColorRepresentation,
  Group,
  LineBasicMaterial,
  Mesh,
  Object3D,
  ShaderMaterial,
  Vector3,
} from 'three';
import { BoxHelper } from 'three';
import { useHelper } from '@react-three/drei';
import { useDracoGeometry, useTexture, useTexturedMesh } from '../../hooks';

class CustomBoxHelper extends BoxHelper {
  constructor(object: Object3D, color?: ColorRepresentation) {
    super(object, color);
    const material = this.material as LineBasicMaterial;
    material.depthTest = false;
    material.transparent = true;
    material.opacity = 1;
    this.renderOrder = 999;
  }
}

export type MeshType = Mesh<BufferGeometry, ShaderMaterial>;

export const ScanBase = (
  {
    scanId,
    processingResultId,
    onOver,
    onClick,
    onLoaded,
    isSelected,
    helperColor,
  }: {
    isSelected?: boolean;
    helperColor?: string;
    onClick?: (event: ThreeEvent<MouseEvent>) => void;
    onLoaded?: (geometryCenter: Vector3) => void;
    onOver?: (event: ThreeEvent<MouseEvent>) => void;
    processingResultId: string;
    scanId: string;
  },
  refToSelf: ForwardedRef<Group>,
) => {
  const userDataRef = useRef({ scanId, processingResultId, type: Object3dNames.Scan });
  const meshRef = useRef<MeshType>(null);
  const [geometryCenterCorrection, setGeometryCenterCorrection] = useState<Vector3>();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  useHelper(isSelected ? refToSelf : null, CustomBoxHelper, helperColor);

  const texturedMesh = useTexturedMesh(processingResultId);
  const geometry = useDracoGeometry(texturedMesh);
  const material = useTexture(texturedMesh);

  useEffect(() => {
    if (!geometry) return;
    geometry.computeBoundingSphere();
    const center = geometry.boundingSphere!.center.clone().negate();
    setGeometryCenterCorrection(center);
  }, [geometry]);

  useEffect(() => {
    if (!meshRef.current?.userData?.processingResultId) return;
    meshRef.current.userData.processingResultId = processingResultId;
  }, [meshRef, processingResultId]);

  useEffect(() => {
    if (!geometryCenterCorrection || !onLoaded) return;
    onLoaded(geometryCenterCorrection.clone());
  }, [geometryCenterCorrection, onLoaded]);

  return (
    <group
      ref={refToSelf}
      name={`${Object3dNames.ScanCentered}:${scanId}`}
      renderOrder={getRenderOrder(Object3dNames.ScanCentered)}
    >
      {geometry && material && geometryCenterCorrection && (
        <mesh
          ref={meshRef}
          name={`${Object3dNames.Scan}:${scanId}`}
          userData={userDataRef.current}
          geometry={geometry}
          material={material}
          onClick={onClick}
          position={geometryCenterCorrection}
          onPointerMove={onOver}
          onUpdate={enableLayerOne}
          renderOrder={getRenderOrder(Object3dNames.Scan)}
        />
      )}
    </group>
  );
};

export const Scan = memo(forwardRef(ScanBase));
