import { Button, useTheme } from '@mui/material';
import { ChevronsDown, ChevronsUp } from '@deepup/icons';
import { FC } from 'react';

interface LegendCollapseBtnProps {
  onClick: () => void;
  label: string;
  collapsed: boolean;
}

export const LegendCollapseBtn: FC<LegendCollapseBtnProps> = ({ onClick, label, collapsed }) => {
  const theme = useTheme();

  return (
    <Button
      fullWidth
      startIcon={collapsed ? <ChevronsDown /> : <ChevronsUp />}
      onClick={onClick}
      sx={{
        minHeight: theme.spacing(5),
      }}
    >
      {label}
    </Button>
  );
};
