import { proxy } from 'valtio';
import type { Photo } from '@photos/types';
import { request } from '@core/api';
import { getPhotoClusters } from './helper';

export type Category = 'ALL' | string;

export interface Photos {
  showPhotos: boolean;
  showPreview: boolean;
  selectedPhoto: Photo | null;
  previewPhotos: Photo[];
  singles: Photo[];
  clusters: Photo[][];
  selectedCategories: Category[];
  possibleCategories: Category[];
  currentPhotoIndex: number;
  isLoading: boolean;
  previewPosition: { x: number; y: number } | null;
  hoverPreviewPosition: { x: number; y: number } | null;
  isHoveringIcon: boolean;
  isHoveringPreview: boolean;
}

export const photosState = proxy<Photos>({
  showPhotos: false,
  showPreview: false,
  selectedPhoto: null,
  previewPhotos: [],
  singles: [],
  clusters: [],
  currentPhotoIndex: 0,
  isLoading: false,
  selectedCategories: [],
  possibleCategories: [],
  previewPosition: null,
  hoverPreviewPosition: null,
  isHoveringIcon: false,
  isHoveringPreview: false,
});

export const togglePreview = (show = !photosState.showPreview): void => {
  photosState.showPreview = show;
};

export const setSelectedPhoto = (photo: Photo | null): void => {
  photosState.selectedPhoto = photo;
};

export const setPhotoCategories = (categories: Category[]): void => {
  photosState.possibleCategories = [...categories.sort()];
};

export const setSelectedCategories = (categories: Category[]): void => {
  photosState.selectedCategories = categories;
};

export const setClustersAndSingles = (photos: Photo[]): void => {
  const thresholdInMeter = 1;
  const [singles, clusters] = getPhotoClusters(photos, thresholdInMeter);
  photosState.singles = singles;
  photosState.clusters = clusters;
};

export const areAllCategoriesSelected = (categories: Category[]): boolean =>
  categories.length === photosState.possibleCategories.length;

export const togglePhotos = (show = !photosState.showPhotos): void => {
  photosState.showPhotos = show;
};

export const setPreviewPhotos = async (photos: Photo[]): Promise<void> => {
  photosState.currentPhotoIndex = 0;
  if (!photos?.length) {
    photosState.previewPhotos = [];
  } else {
    photosState.isLoading = true;
    photosState.previewPhotos = await Promise.all(
      photos.map((photo) => request<Photo>('GET', { path: photo.url })),
    );
    photosState.isLoading = false;
  }
};

export const showNextPhoto = (): void => {
  const photosToDisplay = photosState.previewPhotos;
  if (photosToDisplay.length === 0) return;

  photosState.currentPhotoIndex = (photosState.currentPhotoIndex + 1) % photosToDisplay.length;

  if (
    photosState.selectedPhoto !== null &&
    photosState.previewPhotos.find((p) => p.id === photosState.selectedPhoto?.id)
  ) {
    const newSelectedPhoto = photosToDisplay[photosState.currentPhotoIndex];
    if (photosState.selectedPhoto.id !== newSelectedPhoto.id) {
      photosState.selectedPhoto = newSelectedPhoto;
    }
  }
};

export const openCurrentPhotoInNewTab = (): void => {
  if (!photosState.previewPhotos.length) return;
  window.open(photosState.previewPhotos[photosState.currentPhotoIndex].url, '_blank');
};

export const openPhotoInNewTab = (photo: Photo): void => {
  if (!photo.url) {
    console.error('Photo URL is not available.');
    return;
  }
  window.open(photo.url, '_blank');
};

export const setPreviewPosition = (position: { x: number; y: number } | null): void => {
  photosState.previewPosition = position;
};

export const setIsHoveringIcon = (isHovering: boolean): void => {
  photosState.isHoveringIcon = isHovering;
  photosState.showPreview = isHovering || photosState.isHoveringPreview;
};

export const setIsHoveringPreview = (isHovering: boolean): void => {
  photosState.isHoveringPreview = isHovering;
  photosState.showPreview = isHovering || photosState.isHoveringIcon;
};

export const clearPreviewPhotos = () => {
  photosState.previewPhotos = [];
  photosState.previewPosition = null;
  photosState.currentPhotoIndex = 0;
};

export const updatePhotoInPhotosState = (updatedPhoto: Photo): void => {
  photosState.singles = photosState.singles.map((p) =>
    p.id === updatedPhoto.id ? updatedPhoto : p,
  );
};

export const updatePhotoEverywhere = (updatedPhoto: Photo): void => {
  photosState.singles = photosState.singles.map((p) =>
    p.id === updatedPhoto.id ? updatedPhoto : p,
  );

  photosState.clusters = photosState.clusters.map((cluster) =>
    cluster.map((p) => (p.id === updatedPhoto.id ? updatedPhoto : p)),
  );

  photosState.previewPhotos = photosState.previewPhotos.map((p) =>
    p.id === updatedPhoto.id ? { ...updatedPhoto, url: p.url } : p,
  );

  if (photosState.selectedPhoto?.id === updatedPhoto.id) {
    photosState.selectedPhoto = {
      ...updatedPhoto,
      url: photosState.selectedPhoto.url,
    };
  }
};
