import { PointElementMaterial } from '@deepup/internal-apis/dist/typescript/network/v1/inetwork';
import { Autocomplete, Grid, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export default function MaterialSelectAutocomplete({
  updateProp,
  currentMaterialId,
  required = false,
  materials,
}: {
  subType: string;
  updateProp: (key: string, value: string | null) => void;
  currentMaterialId: string | null;
  required?: boolean;
  materials: PointElementMaterial[];
}) {
  const [material, setMaterial] = useState<PointElementMaterial | null>(null);

  useEffect(() => {
    const currentMaterial = materials.find((m) => m.id === currentMaterialId);
    setMaterial(currentMaterial ?? null);
  }, [currentMaterialId, materials]);

  return (
    <Autocomplete
      options={materials}
      autoHighlight
      getOptionLabel={({ name, manufacturer, group, id }) =>
        `${id} | ${name} | ${group} ${manufacturer ? '|' : ''} ${manufacturer}`
      }
      componentsProps={{ paper: { elevation: 24 } }}
      onChange={(_, value) => {
        setMaterial(value);
        updateProp('materialId', value?.id ?? null);
      }}
      getOptionKey={(option) => option.id}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Material"
          required={required}
          disabled={materials.length === 0}
        />
      )}
      renderOption={(props, option) => (
        <ListItem
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          key={option.id}
          disableGutters
        >
          <Grid item xs={12} flexGrow={1}>
            <ListItemText>{option.id}</ListItemText>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="secondary">
              {option.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="secondary">
              {option.group}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="secondary">
              {option.manufacturer}
            </Typography>
          </Grid>
        </ListItem>
      )}
      value={material}
    />
  );
}
