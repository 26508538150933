import React, { useState, useEffect } from 'react';
import { Chip, Stack, Typography, Button, IconButton } from '@mui/material';
import { CheckmarkCirclePlusOutline, Plus, TrashcanOutline, XCircleFilled } from '@deepup/icons';
import { OrderNumber } from '@projects/types/orderNumbers';
import { request } from '@core/api';
import type { Photo } from '@photos/types';
import type { Scan } from '@scans/types';
import { photosState, updatePhotoEverywhere } from '@photos/components/PhotosInViewport/state';
import { setSelectedScan, updateScanInScansState } from '@scans/components/Scans/state';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@core/redux/store';
import { scanThunks } from '@scans/redux';
import AddSMADialog from './AddSMADialog';

interface SMADetailsProps {
  projectId: string | undefined;
  photoId: string | undefined;
  scanId: string | undefined;
  smaNumbers: OrderNumber[] | null;
}

export const SMADetails: React.FC<SMADetailsProps> = ({
  projectId,
  photoId,
  scanId,
  smaNumbers,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [displayedSMANumbers, setDisplayedSMANumbers] = useState<OrderNumber[]>([]);
  const [selectedSMAs, setSelectedSMAs] = useState<OrderNumber[]>([]);
  const [showSaveCancel, setShowSaveCancel] = useState<boolean>(false);
  const [smasToDelete, setSmasToDelete] = useState<string[]>([]);

  const isPhoto = Boolean(photoId);
  const entityId = isPhoto ? photoId : scanId;
  const entityType = isPhoto ? 'photos' : 'scans';

  useEffect(() => {
    setDisplayedSMANumbers(smaNumbers ?? []);
  }, [smaNumbers]);

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleAddSMA = (newSMA: OrderNumber) => {
    if (
      displayedSMANumbers.some((sma) => sma.value === newSMA.value) ||
      selectedSMAs.some((sma) => sma.value === newSMA.value)
    ) {
      return;
    }

    setSelectedSMAs((prev) => [...prev, newSMA]);
    setShowSaveCancel(true);
  };

  const handleRemoveSelectedSMA = (smaToRemove: OrderNumber) => {
    setSelectedSMAs((prev) => prev.filter((sma) => sma.value !== smaToRemove.value));
    if (selectedSMAs.length === 1) {
      setShowSaveCancel(false);
    }
  };

  const handleSaveChanges = async () => {
    const updatedOrderNumbers = [...displayedSMANumbers, ...selectedSMAs];
    const requestBody = {
      orderNumbers: updatedOrderNumbers,
    };

    try {
      const updatedEntity = await request<Photo | Scan, typeof requestBody>('PATCH', {
        path: ['projects', projectId ?? '', entityType, entityId ?? ''],
        body: requestBody,
      });
      const existingPhoto =
        photosState.singles.find((p) => p.id === updatedEntity.id) ||
        photosState.clusters.flat().find((p) => p.id === updatedEntity.id);

      if (existingPhoto) {
        updatePhotoEverywhere({
          ...existingPhoto,
          ...updatedEntity,
        });
      } else {
        await dispatch(
          scanThunks.getOne({
            path: ['scans', updatedEntity.id],
            params: { projection: 'scanDetail' },
          }),
        );
        setSelectedScan(updatedEntity as Scan);
        updateScanInScansState(updatedEntity as Scan);
      }

      setDisplayedSMANumbers(updatedOrderNumbers);
      setSelectedSMAs([]);
      setShowSaveCancel(false);
    } catch (error) {
      console.error('Failed to save changes:', error);
    }
  };

  const handleCancelChanges = () => {
    setSelectedSMAs([]);
    setShowSaveCancel(false);
  };

  const handleMarkForDeletion = (smaValue: string) => {
    if (showSaveCancel) {
      return;
    }
    setSmasToDelete((prev) =>
      prev.includes(smaValue) ? prev.filter((sma) => sma !== smaValue) : [...prev, smaValue],
    );
  };

  const handleDeleteMarkedSMAs = async () => {
    const updatedOrderNumbers = displayedSMANumbers.filter(
      (sma) => !smasToDelete.includes(sma.value),
    );
    const requestBody = {
      orderNumbers: updatedOrderNumbers,
    };

    try {
      const updatedEntity = await request<Photo | Scan, typeof requestBody>('PATCH', {
        path: ['projects', projectId ?? '', entityType, entityId ?? ''],
        body: requestBody,
      });

      if ('url' in updatedEntity && photoId) {
        const currentUrl = photosState.selectedPhoto?.url;
        if (currentUrl) {
          updatePhotoEverywhere({
            ...updatedEntity,
            url: currentUrl,
          });
        }
      } else {
        await dispatch(
          scanThunks.getOne({
            path: ['scans', updatedEntity.id],
            params: { projection: 'scanDetail' },
          }),
        );
        setSelectedScan(updatedEntity as Scan);
        updateScanInScansState(updatedEntity as Scan);
      }

      setDisplayedSMANumbers(updatedOrderNumbers);
      setSmasToDelete([]);
    } catch (error) {
      console.error('Failed to delete SMAs:', error);
    }
  };

  const handleCancelDeletion = () => {
    setSmasToDelete([]);
  };

  return (
    <Stack p={2} spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="overline">SMA</Typography>
        <IconButton color="default" size="medium" onClick={handleOpenDialog}>
          <Plus />
        </IconButton>
      </Stack>

      <Stack direction="row" flexWrap="wrap" gap={1}>
        {displayedSMANumbers.length > 0 ? (
          displayedSMANumbers.map((smaInfo, index) => (
            <Chip
              key={`${smaInfo.value}-${index}`}
              label={`${smaInfo.value} (${smaInfo.subType})`}
              onDelete={() => handleMarkForDeletion(smaInfo.value)}
              deleteIcon={<XCircleFilled />}
              variant="outlined"
              sx={(theme) => ({
                borderColor: smasToDelete.includes(smaInfo.value)
                  ? theme.palette.error.main
                  : theme.palette.divider,
                color: smasToDelete.includes(smaInfo.value)
                  ? theme.palette.error.main
                  : theme.palette.text.primary,
                '& .MuiChip-deleteIcon': {
                  color: smasToDelete.includes(smaInfo.value)
                    ? theme.palette.error.main
                    : theme.palette.text.secondary,
                },
              })}
            />
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No SMA numbers selected.
          </Typography>
        )}
      </Stack>

      {selectedSMAs.length > 0 && (
        <>
          <Typography variant="subtitle2">Pending SMAs:</Typography>
          <Stack direction="row" flexWrap="wrap" gap={1}>
            {selectedSMAs.map((sma, index) => (
              <Chip
                key={`pending-${sma.value}-${index}`}
                label={`${sma.value} (${sma.subType})`}
                onDelete={() => handleRemoveSelectedSMA(sma)}
                color="success"
                variant="outlined"
                sx={(theme) => ({
                  borderColor: theme.palette.success.main,
                  color: theme.palette.success.main,
                  '& .MuiChip-deleteIcon': {
                    color: theme.palette.success.main,
                  },
                })}
              />
            ))}
          </Stack>
        </>
      )}

      {smasToDelete.length > 0 && (
        <Stack direction="row" justifyContent="flex-end" padding={1} spacing={2}>
          <Button onClick={handleCancelDeletion} color="secondary" variant="outlined">
            CANCEL
          </Button>
          <Button
            onClick={handleDeleteMarkedSMAs}
            startIcon={<TrashcanOutline />}
            color="error"
            variant="contained"
            disabled={smasToDelete.length === 0}
          >
            DELETE
          </Button>
        </Stack>
      )}

      {showSaveCancel && (
        <Stack direction="row" justifyContent="flex-end" padding={1} spacing={2}>
          <Button onClick={handleCancelChanges} color="secondary" variant="outlined">
            CANCEL
          </Button>
          <Button
            onClick={handleSaveChanges}
            startIcon={<CheckmarkCirclePlusOutline />}
            color="primary"
            variant="contained"
          >
            SAVE CHANGES
          </Button>
        </Stack>
      )}

      <AddSMADialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onAddSMA={handleAddSMA}
        selectedSMAs={selectedSMAs}
        onRemoveSelectedSMA={handleRemoveSelectedSMA}
        entityType={entityType}
        entityId={entityId}
      />
    </Stack>
  );
};
