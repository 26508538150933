import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Chip,
} from '@mui/material';
import { Plus } from '@deepup/icons';
import { OrderNumber, OrderNumberSubType, OrderNumberType } from '@projects/types/orderNumbers';

interface AddSMADialogProps {
  open: boolean;
  onClose: () => void;
  onAddSMA: (sma: OrderNumber) => void;
  selectedSMAs: OrderNumber[];
  onRemoveSelectedSMA: (sma: OrderNumber) => void;
  entityType: string;
  entityId: string | undefined;
}

const AddSMADialog: React.FC<AddSMADialogProps> = ({
  open,
  onClose,
  onAddSMA,
  selectedSMAs,
  onRemoveSelectedSMA,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedSubType, setSelectedSubType] = useState<OrderNumberSubType>(
    OrderNumberSubType.APL,
  );

  const handleAdd = () => {
    if (inputValue.trim() === '') return;

    const newSMA: OrderNumber = {
      type: OrderNumberType.SMA,
      value: inputValue.trim(),
      subType: selectedSubType,
    };

    onAddSMA(newSMA);
    setInputValue('');
    setSelectedSubType(OrderNumberSubType.APL);
  };

  const handleSave = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        elevation: 11,
        style: {
          width: '360px',
        },
      }}
    >
      <DialogTitle>Add SMAs to Asset</DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={1}>
          <TextField
            label="SMA Number"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter SMA Number"
            fullWidth
            autoFocus
          />

          <FormControl fullWidth>
            <InputLabel id="subtype-label">SubType</InputLabel>
            <Select
              labelId="subtype-label"
              value={selectedSubType}
              label="SubType"
              onChange={(e) => setSelectedSubType(e.target.value as OrderNumberSubType)}
            >
              {Object.values(OrderNumberSubType).map((subType) => (
                <MenuItem key={subType} value={subType}>
                  {subType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            onClick={handleAdd}
            startIcon={<Plus />}
            disabled={inputValue.trim() === ''}
          >
            Add SMA
          </Button>

          {selectedSMAs.length > 0 && (
            <>
              <Typography variant="subtitle2">Selected SMAs:</Typography>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {selectedSMAs.map((sma) => (
                  <Chip
                    key={sma.value}
                    label={`${sma.value} (${sma.subType})`}
                    onDelete={() => onRemoveSelectedSMA(sma)}
                    color="primary"
                    sx={{
                      fontWeight: 600,
                    }}
                  />
                ))}
              </Stack>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={onClose} color="secondary" variant="outlined">
          CANCEL
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={selectedSMAs.length === 0}
        >
          ADD SMAs
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSMADialog;
