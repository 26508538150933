import React, { FC, useEffect, useState } from 'react';
import { IconButton, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { DotFilled, EyeFilled, EyeSlashedFilled, XOutline } from '@deepup/icons';
import { useSnapshot } from 'valtio';
import { toHumanReadable } from '@core/logic';
import { CustomScrollContainer } from '@core/components';
import { attributeState } from './state';
import type { LegendItemsProps } from './types';

export const LegendItems: FC<LegendItemsProps> = ({
  attributeList,
  attribute,
  callback,
  colors,
  toggleVisibility,
  currentType,
  onRemove,
  selected,
}) => {
  const { showOnlyCurrentDepth, showOnlyCurrentWidth, depth, width } = useSnapshot(attributeState);
  const [showVisibilityIcon, setShowVisibilityIcon] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    switch (currentType as string) {
      case 'DEPTH':
        setIsActive(showOnlyCurrentDepth);
        setShowVisibilityIcon(true);
        break;
      case 'WIDTH':
        setIsActive(showOnlyCurrentWidth);
        setShowVisibilityIcon(true);
        break;
      default:
        setShowVisibilityIcon(false);
        break;
    }
  }, [currentType, width, depth, showOnlyCurrentDepth, showOnlyCurrentWidth]);

  return (
    <CustomScrollContainer
      role="list"
      sx={{
        flex: 1,
      }}
    >
      {attributeList.map((currentAttribute) => (
        <ListItemButton
          key={currentAttribute}
          onClick={() => callback(currentAttribute)}
          selected={currentAttribute === attribute}
          dense
        >
          <ListItemIcon>
            <DotFilled fill={colors[currentAttribute]} />
          </ListItemIcon>
          <ListItemText primary={toHumanReadable(currentAttribute)} />
          {showVisibilityIcon && currentAttribute === attribute && (
            <IconButton
              size="small"
              onClick={(e) => {
                // Prevent triggering the ListItemButton's onClick
                e.stopPropagation();
                toggleVisibility();
              }}
              data-testid={`selectShowOnlySelected${toHumanReadable(currentType)}`}
            >
              {isActive ? <EyeSlashedFilled /> : <EyeFilled />}
            </IconButton>
          )}
        </ListItemButton>
      ))}
      <ListItemButton selected={selected} onClick={onRemove} dense>
        <ListItemIcon>
          <XOutline fill={theme.palette.error.main} />
        </ListItemIcon>
        <ListItemText primary={`Remove ${toHumanReadable(currentType)}`} />
      </ListItemButton>
    </CustomScrollContainer>
  );
};
