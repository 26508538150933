import type { Entity } from '@core/types';
import type { FullAddress, SparseAddress } from '@core/types/address';
import type { OrderNumber } from '@projects/types/orderNumbers';

// POINT ELEMENT

export type PointElementKind = 'NETWORK_NODE';
export const pointElementSubtype = [
  'HAUPTVERTEILER',
  'POINT_OF_PRESENCE_REGIO',
  'POINT_OF_PRESENCE_CONTAINER',
  'MULTIFUNKTIONSGEHAEUSE',
  'NETZVERTEILER',
  'HAUSANSCHLUSS',
  'GLASFASER_MUFFE',
  'HAUPTKABEL',
  'KABELSCHACHT',
  'ABSCHLUSSPUNKT',
] as const;

export type PointElementSubtype = typeof pointElementSubtype[number];

export interface PointElement extends Entity {
  kind: PointElementKind;
  parentPointElementId: string | null;
  subtype: PointElementSubtype;
  address: FullAddress;
  name?: string;
  projectId: string;
  pointId: string | null; // Reference to a geometric element point
  smaNumber: string | null;
  materialId: string | null;
}

export type PartialPointEl = Omit<PointElement, keyof Entity | 'projectId'>;
export const houseLeadVariants = [null, 'PASSED', 'PASSED_PLUS', 'HOMES_PREPARED', 'BP_PLUS'];

export type HouseLeadVariant = typeof houseLeadVariants[number];

// SPLINE ELEMENT TYPES

export const splineElementKind = ['SPEEDPIPE', 'SPEEDPIPE_BUNDLE', 'OTHER'] as const;
export type SplineElementKind = typeof splineElementKind[number];

export interface SplineElementType extends Entity {
  kind: SplineElementKind;
  name: string;
  color: string; // hex
  directBuried?: boolean;
}

export interface BundleType extends SplineElementType {
  kind: 'SPEEDPIPE_BUNDLE';
  children: string[]; // the contained speed pipe types
}

export interface SpeedPipeType extends SplineElementType {
  kind: 'SPEEDPIPE';
  innerDiameter: number; // dezimilimeter
  outerDiameter: number; // dezimilimeter
}

export interface OtherType extends SplineElementType {
  kind: 'OTHER';
} // Deprecated!

export type GetElementType<T extends SplineElementKind> = T extends 'SPEEDPIPE_BUNDLE'
  ? BundleType
  : T extends 'SPEEDPIPE'
  ? SpeedPipeType
  : OtherType;

// SPLINE ELEMENT

export interface SplineElement extends Entity {
  fromPoint?: {
    // from- and toPoints are optional because an element can be created without being labeled on a trench
    // is set with first labeling
    id: string; // changes via PATCH /trenches/:id/splineElements/:id/(extend|shrink) -> response: whole Trench including Elements
    isFinished: boolean; // changes via PATCH /splineElements/:id { isFinished: (true|false) }
  };
  toPoint?: {
    id: string;
    isFinished: boolean;
  };
  houseLeadVariant?: HouseLeadVariant;
  address: SparseAddress | null;
  splineElementTypeId: string; // reference to a SplineElementType
  name: string;
  parentPointElementId: string | null;
  projectId: string;
  orderNumbers?: OrderNumber[];
}

export type PartialSplineEl = Omit<SplineElement, keyof Entity | 'projectId'>;
