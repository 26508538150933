import { useEffectOnce } from '@core/logic';
import { Stack, TextField, Typography, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { SearchOutline } from '@deepup/icons';
import { projectThunks } from '@projects/redux';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { ProjectListAll } from './ProjectListAll';
import { ProjectListPinned } from './ProjectListPinned';

const maxWidth = '1366px'; // TODO centralize! as per design https://www.figma.com/file/zs8wJ1g9TlxATIIEMUnUxp/BO-Project-List?type=design&node-id=58-21767&mode=design&t=qeu1vaAEaypdmUZo-4
const allProjectsSize = 10000; // FIXME This is a hot fix, until Deepup reaches 10000 projects. Switch to server side pagination!

export const ProjectsList = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const theme = useTheme();

  const fetchProjects = () => {
    dispatch(
      projectThunks.getMany({
        path: ['projects'],
        params: {
          size: allProjectsSize,
          projection: 'scanCount',
        },
      }),
    );
  };

  useEffectOnce(fetchProjects);

  return (
    <Stack alignItems="center">
      <Stack padding={3} gap={3} maxWidth={maxWidth} width="100%">
        <Stack gap={3} direction="row" justifyContent="space-between">
          <Typography variant="h4" color="text.primary">
            Projects
          </Typography>
          <TextField
            type="search"
            placeholder="Search project"
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutline fontSize={24} fill={theme.palette.action.active} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <ProjectListPinned search={search} />
        <ProjectListAll search={search} />
      </Stack>
    </Stack>
  );
};
