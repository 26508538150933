import { request } from '@core/api';
import { AppDispatch } from '@core/redux/store';
import { CheckmarkCirclePlusOutline, Plus, TrashcanOutline, XCircleFilled } from '@deepup/icons';
import { pointElementThunks } from '@elements/redux/point-element.slice';
import { Button, Chip, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PointElementAddSMADialog } from './PointElementAddSMADialog';

interface PointElementSMADetailsProps {
  projectId: string;
  pointElementId: string;
  smaNumber: string | null;
}

export const PointElementSMADetails: React.FC<PointElementSMADetailsProps> = ({
  projectId,
  pointElementId,
  smaNumber,
}) => {
  if (!pointElementId) {
    return null;
  }
  const dispatch = useDispatch<AppDispatch>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [currentSmaNumber, setCurrentSmaNumber] = useState<string | null>(smaNumber);
  const [selectedSma, setSelectedSma] = useState<string>('');
  const [showSaveCancel, setShowSaveCancel] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSelectedForDeletion, setIsSelectedForDeletion] = useState(false);

  useEffect(() => {
    setCurrentSmaNumber(smaNumber);
  }, [smaNumber]);

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => setIsDialogOpen(false);

  const handleAddSMA = (newSmaValue: string) => {
    setSelectedSma(newSmaValue);
    setCurrentSmaNumber(newSmaValue);
    setShowSaveCancel(true);
    setError(null);
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    setError(null);
    const requestBody = {
      smaNumber: selectedSma,
    };

    interface UpdatedEntity {
      smaNumber: string | null;
    }

    const updatedEntity = await request<UpdatedEntity, typeof requestBody>('PATCH', {
      path: ['projects', projectId, 'pointElements', pointElementId],
      body: requestBody,
    });

    setCurrentSmaNumber(updatedEntity.smaNumber);
    setShowSaveCancel(false);
    setSelectedSma('');
    handleCloseDialog();

    dispatch(
      pointElementThunks.getMany({
        path: ['projects', projectId, 'pointElements'],
      }),
    );
  };

  const handleCancelChanges = () => {
    setSelectedSma('');
    setCurrentSmaNumber(smaNumber);
    setShowSaveCancel(false);
    setError(null);
  };

  const handleDeleteSMA = async () => {
    setIsLoading(true);
    setError(null);
    const requestBody = {
      smaNumber: null,
    };

    interface UpdatedEntity {
      smaNumber: string | null;
    }

    const updatedEntity = await request<UpdatedEntity, typeof requestBody>('PATCH', {
      path: ['projects', projectId, 'pointElements', pointElementId],
      body: requestBody,
    });

    setCurrentSmaNumber(updatedEntity.smaNumber);

    dispatch(
      pointElementThunks.getMany({
        path: ['projects', projectId, 'pointElements'],
      }),
    );

    setIsSelectedForDeletion(false);
  };

  return (
    <Stack p={2} spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="overline">SMA</Typography>
        <IconButton color="default" size="medium" onClick={handleOpenDialog}>
          <Plus />
        </IconButton>
      </Stack>

      <Stack direction="row" flexWrap="wrap" gap={1}>
        {currentSmaNumber ? (
          <Chip
            label={`${currentSmaNumber}`}
            onDelete={() => {
              if (selectedSma) {
                setSelectedSma('');
                setCurrentSmaNumber('');
                setShowSaveCancel(false);
              } else {
                setIsSelectedForDeletion(true);
              }
            }}
            deleteIcon={<XCircleFilled />}
            variant="outlined"
            sx={(theme) => ({
              borderColor: isSelectedForDeletion ? theme.palette.error.main : theme.palette.divider,
              color: isSelectedForDeletion ? theme.palette.error.main : theme.palette.text.primary,
              '& .MuiChip-deleteIcon': {
                color: isSelectedForDeletion
                  ? theme.palette.error.main
                  : theme.palette.text.secondary,
              },
            })}
          />
        ) : (
          <Typography variant="body2" color="textSecondary">
            No SMA number selected.
          </Typography>
        )}
      </Stack>

      {showSaveCancel && (
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button onClick={handleCancelChanges} color="secondary" variant="outlined">
            CANCEL
          </Button>
          <Button
            onClick={handleSaveChanges}
            startIcon={<CheckmarkCirclePlusOutline />}
            color="primary"
            variant="contained"
            disabled={isLoading || selectedSma.trim() === ''}
          >
            {isLoading ? 'Saving...' : 'SAVE CHANGES'}
          </Button>
        </Stack>
      )}

      {isSelectedForDeletion && (
        <Stack direction="row" justifyContent="flex-end" padding={1} spacing={2}>
          <Button
            onClick={() => setIsSelectedForDeletion(false)}
            color="secondary"
            variant="outlined"
          >
            CANCEL
          </Button>
          <Button
            onClick={handleDeleteSMA}
            startIcon={<TrashcanOutline />}
            color="error"
            variant="contained"
            disabled={!isSelectedForDeletion}
          >
            DELETE
          </Button>
        </Stack>
      )}

      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}

      <PointElementAddSMADialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onAddSMA={handleAddSMA}
        entityType="pointElements"
        entityId={pointElementId}
      />
    </Stack>
  );
};
