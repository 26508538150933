import { type ThreeEvent, useThree } from '@react-three/fiber';
import { VisibleScans } from '@viewer3D/types';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Group, Vector3 } from 'three';
import { Scan } from '../Scan';
import { useConnect } from './connect';

const TransformableScanBase = ({
  scanId,
  onOver,
  onClick,
  visibleScans,
}: {
  onClick?: (event: ThreeEvent<MouseEvent>) => void;
  onOver?: (event: ThreeEvent<MouseEvent>) => void;
  scanId: string;
  visibleScans: VisibleScans;
}) => {
  const {
    moveCameraTo,
    scan,
    onScanLoadedToScene,
    userTransformation,
    initialCameraSet,
    isSelected,
  } = useConnect(scanId);
  const invalidate = useThree((state) => state.invalidate);
  const centeredScanRef = useRef<Group>(null);
  const [geometryCenterCorrection, setGeometryCenterCorrection] = useState<Vector3>();

  const scanLoadedHandler = useCallback(
    (geoCenterCorrection: Vector3) => {
      setGeometryCenterCorrection(geoCenterCorrection);
      onScanLoadedToScene();
      invalidate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invalidate],
  );

  useEffect(() => {
    const { current: centeredScan } = centeredScanRef;
    // TODO: Reduce amounts of re-renders (currently too many redundant transformations)
    //  This hook should run only at initialisation time but after all dependencies exist.
    if (!centeredScan || !geometryCenterCorrection) return;
    const { position, rotation } = userTransformation;
    centeredScan.position.set(0, 0, 0).copy(position).sub(geometryCenterCorrection);
    centeredScan.rotation.set(0, 0, 0).copy(rotation);
    invalidate();

    if (initialCameraSet) return;

    moveCameraTo(centeredScan.position);
  }, [
    initialCameraSet,
    centeredScanRef,
    userTransformation,
    geometryCenterCorrection,
    invalidate,
    moveCameraTo,
  ]);

  if (
    !scan.selectedProcessingResultId ||
    visibleScans === 'NONE' ||
    (visibleScans === 'ONLY_VISIBLE' && !scan.visible)
  )
    return null;

  return (
    <Scan
      isSelected={isSelected}
      helperColor={userTransformation.helperColor}
      ref={centeredScanRef}
      scanId={scanId}
      processingResultId={scan.selectedProcessingResultId}
      onLoaded={scanLoadedHandler}
      onClick={onClick}
      onOver={onOver}
    />
  );
};

export const TransformableScan = memo(TransformableScanBase);
