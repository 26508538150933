import { ChevronsLeft, ChevronsRight } from '@deepup/icons';
import { IconButton, Paper, Stack, Typography, useTheme } from '@mui/material';

interface LegenNavProps {
  label: string;
  onPrevClick: () => void;
  onNextClick: () => void;
}

export const LegendNav = ({ onPrevClick, onNextClick, label }: LegenNavProps) => {
  const theme = useTheme();

  return (
    <Paper elevation={2} sx={{ minHeight: theme.spacing(5) }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <IconButton onClick={onPrevClick}>
          <ChevronsLeft />
        </IconButton>
        <Typography fontWeight="bold" variant="overline">
          {label}
        </Typography>
        <IconButton onClick={onNextClick}>
          <ChevronsRight data-testid="nextAttribute" />
        </IconButton>
      </Stack>
    </Paper>
  );
};
